import Vue from 'vue'
import {debounce} from 'lodash'

let ivBarChart = Vue.component('iv-bar-chart', {
    props: [
        'labels',
        'columns',
        'max',
        'showYAxis',
        'format',
        'showOverlay',
    ],

    data: function () {
        return {
            chartStyle: 'height:130px',
            // @todo: make this a computed prop based on the max value, then alter the overly css to match
            yAxisStepQty: 3,
        }
    },

    mounted: function () {
        this.setChartStyle()
        window.addEventListener('resize', debounce(this.setChartStyle, 700))
    },

    methods: {
        setChartStyle: function () {
            let height = 130
            if (typeof this.$refs.chart !== 'undefined') {
                height = Math.ceil(.35 * this.$refs.chart.clientWidth)
            }
            if (height < 130) {
                height = 130
            }
            this.chartStyle = `height:${height}px`
        },

        makeBarStack: function(barStack) {
            let bar = {
                stack: [],
            }
            let minPercent = Math.round(barStack[0][0] / this.yAxisMax * 100)
            let totalPercent = minPercent
            bar.stack.push({
                classNames: 'chart__bar-below',
                style: `flex-basis:${minPercent}%; height:${minPercent}%`,
            })

            let stackLabel = 0
            for (let s in barStack) {
                let lowHigh = barStack[s]
                let low = lowHigh[0]
                let high = lowHigh[1]
                let barHeight = high - low
                stackLabel += barHeight
                let percent = Math.round(barHeight / this.yAxisMax * 100)
                totalPercent += percent
                let stack = {
                    classNames: 'chart__bar',
                    style: `flex-basis:${percent}%; height:${percent}%`,
                }
                if (parseInt(s) === barStack.length - 1) {
                    stack.label = this.formatYLabel(stackLabel)
                }
                bar.stack.push(stack)
            }

            bar.stack.push({
                classNames: 'chart__bar-above',
                style: `flex-basis:${100 - totalPercent}%; height:${100 - totalPercent}%`,
            })

            return bar
        },

        formatYLabel: function(label) {
            if (this.$props.format === 'currency') {
                return this.$parent.format_dollar(label)
            }

            else {
                return `${this.$parent.round(label, 1)}%`
            }
        },
    },

    computed: {
        barStacks: function () {
            let barStacks = {}
            // Compress the multi-columns with null data into single column.
            for (let c in this.$props.columns) {
                let column = this.$props.columns[c]
                // Regular bar chart
                if (column === parseFloat(column)) {
                    let low = 0
                    let high = column
                    barStacks[c] = this.makeBarStack([[low, high]])
                    barStacks[c].label = this.$props.labels[c]
                    if (barStacks[c].label.substr(0, 5) === 'Your ') {
                        barStacks[c].stack[1].classNames = barStacks[c].stack[1].classNames + ' highlight'
                        barStacks[c].stack[2].classNames = barStacks[c].stack[2].classNames + ' highlight'
                    }
                }
                else if (Array.isArray(column)) {
                    let columnStack = column
                    barStacks[c] = this.makeBarStack(columnStack)
                    barStacks[c].label = this.$props.labels[c]
                }
            }
            return barStacks
        },

        yAxisStep: function () {
            return Math.ceil(this.$props.max / this.yAxisStepQty)
        },

        yAxisMax: function () {
            return this.yAxisStep * this.yAxisStepQty
        },

        yAxisLabels: function () {
            if (this.$props.showYAxis) {
                let yAxisLabels = []
                for (let i = 0; i <= this.yAxisStepQty; i++) {
                    let yAxisLabel = this.yAxisStep * i
                    yAxisLabels.push(this.formatYLabel(yAxisLabel))
                }
                return yAxisLabels
            }
            else {
                return []
            }
        },
    },

    template: '#iv-bar-chart',
})

export {ivBarChart}