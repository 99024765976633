import $ from 'jquery'
import {Optionplan} from './optionplan'
import SentryWrapper from '../../../ui/js/utils/SentryWrapper'

// On doc.ready.
$(function () {
    new SentryWrapper()
    Optionplan.init()
})


